import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";
  
  return (
    <svg 
      width="100%"
      height="100%" 
      viewBox="0 0 24 24" 
      fill="none" xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path d="M11.9514 19.9514L14.9027 19.9514C17.6641 19.9514 19.9027 17.7128 19.9027 14.9514L19.9027 12" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.9513 19.9513L8.99999 19.9513C6.23857 19.9513 4 17.7128 4 14.9513L4 9.04864C4 6.28722 6.23857 4.04865 9 4.04865L11.9514 4.04865" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.3166 14.481L8.81498 15.5582C8.61511 15.6179 8.42822 15.431 8.49052 15.2338L9.56774 11.7321L16.3322 4.96765C16.841 4.45889 17.6638 4.46149 18.17 4.96765L19.0785 5.87616C19.5873 6.38492 19.5847 7.20777 19.0785 7.71393L12.314 14.4784L12.3166 14.481Z" stroke={stroke} stroke-miterlimit="10" stroke-linejoin="round"/>
      <path d="M15.6675 5.63196L18.4164 8.38083" stroke={stroke} stroke-miterlimit="10" stroke-linejoin="round"/>
      <path d="M9.5675 11.732L12.3164 14.4809" stroke={stroke} stroke-miterlimit="10" stroke-linejoin="round"/>
    </svg>
  );
}

export default function EditIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
