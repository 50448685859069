export default (
  <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="30" height="30" rx="10" fill="var(--textUuiSlateBlue)" opacity="0.1" />
    <g clip-path="url(#clip0_1_198)">
      <path d="M11.47 12.1399L7.5 14.5799L11.47 16.9799" stroke="var(--textUuiSlateBlue)" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.79 10.5L13.45 20.32" stroke="var(--textUuiSlateBlue)" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.44 16.9799L22.41 14.5399L18.44 12.1399" stroke="var(--textUuiSlateBlue)" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1_198">
        <rect width="15.91" height="10.82" fill="white" transform="translate(7 10)"/>
      </clipPath>
    </defs>
  </svg>
);
