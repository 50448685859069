import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg 
      width="100%" 
      height="100%" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path d="M10.6876 8.36269L12.7071 6.34316C14.074 4.97632 16.29 4.97632 17.6569 6.34316V6.34316C19.0237 7.70999 19.0237 9.92606 17.6569 11.2929L15.6373 13.3124" stroke={stroke} stroke-linecap="round"/>
      <path d="M13.3124 15.6373L11.2929 17.6569C9.92603 19.0237 7.70996 19.0237 6.34312 17.6569V17.6569C4.97629 16.29 4.97629 14.074 6.34312 12.7071L8.36266 10.6876" stroke={stroke} stroke-linecap="round"/>
      <path d="M14.8285 9.1716L9.17164 14.8285" stroke={stroke} stroke-linecap="round"/>
    </svg>
  );
}

export default function ChainLinkIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
