import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)";

  return (
    <svg 
      width="100%" 
      height="100%" 
      viewBox="0 0 53 53" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path d="M46.0588 26.5544V37.408C46.0588 40.4692 43.5794 42.9612 40.5055 42.9612H12.0308C8.96955 42.9612 6.47754 40.4819 6.47754 37.408V26.5544" stroke={stroke} stroke-linejoin="round"/>
      <path d="M33.8011 27.3641L30.3983 32.5505C30.1327 32.9553 29.6773 33.2083 29.1839 33.2083H26.2745H23.365C22.8717 33.2083 22.4163 32.9553 22.1507 32.5505L18.7479 27.3641C18.419 26.8581 17.8624 26.5545 17.2552 26.5545H6.47754L12.7012 15.5365C13.9156 13.3987 16.1799 12.0704 18.6467 12.0704H33.8897C36.3564 12.0704 38.6207 13.3987 39.8351 15.5365L46.0588 26.5545H35.2938C34.6866 26.5545 34.13 26.8581 33.8011 27.3641Z" stroke={stroke} stroke-linejoin="round"/>
    </svg>

  );
}

export default function InboxIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
