import React from 'react';

interface ClickhouseIconProps extends React.SVGProps<SVGSVGElement> {}

const ClickhouseIcon: React.FC<ClickhouseIconProps> = ({ className, style, ...props }) => {
  const strokeColor = "var(--textUuiSlateBlue)";

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      {...props}
    >
      <rect width="30" height="30" rx="10" fill={strokeColor} opacity="0.1" />
      <g clipPath="url(#clip0_57_14)">
        <path d="M8.5 21.92V9" stroke={strokeColor} strokeLinejoin="round" />
        <path d="M11.59 21.92V9" stroke={strokeColor}  strokeLinejoin="round" />
        <path d="M14.68 21.92V9" stroke={strokeColor}  strokeLinejoin="round" />
        <path d="M17.77 21.92V9" stroke={strokeColor}  strokeLinejoin="round" />
        <path d="M20.85 16.68V14.24" stroke={strokeColor}  strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_57_14">
          <rect width="13.35" height="12.92" fill="white" transform="translate(8 9)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClickhouseIcon;
export { ClickhouseIcon };
